import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { OrderDetailsService } from '../../services/orderDetails.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';

@Component({
  selector: 'app-recreatecancel',
  templateUrl: './recreate-cancel.component.html',
  styleUrl: './recreate-cancel.component.scss',
})
export class RecreatecancelComponent implements OnInit, OnDestroy {

  orderDetails: Observable<any>;
  date: any;
  orderDetailsarray: any;
  orderTablearray: any = [];
  dateformated: any = [];
  modalpopup: boolean;
  updatepoup: boolean;
  comment: string = '';
  datecal: boolean;
  displayCart: boolean;
  role: boolean;
  displayCancelRole: boolean;
  showError: string = '';
  private unsubscribe$ = new Subject<void>();
  displayRole: boolean;

  constructor(
    private orderService: OrderDetailsService,
    private router: Router,
    private globalService: GlobalService,
    private storageService: StorageService,
    public changeRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.globalService.loadingSubject.next(true); 
    let userRoles = this.storageService.userRoles;
    this.displayCancelRole = userRoles.includes('Sales Rep');
    this.displayRole = userRoles.includes('Requester') && !userRoles.includes('Purchaser')
    this.displayCart =
      userRoles.includes('SGRE Admin') ||
      userRoles.includes('Sales Rep') ||
      (userRoles.includes('Purchaser') && !userRoles.includes('Requester'));

    this.orderService.OrderListData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.orderDetailsarray = data;
        let obj = {
          status: data?.status,
          date: data?.date,
          deliverymode: data?.deliveryMode?.name,
        };
        this.orderTablearray.push(obj);
        let dateSent = new Date(data.date);
        let now = new Date();
        let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        var datasentt = new Date(
          dateSent.getTime() + dateSent.getTimezoneOffset() * 60000
        );
        this.date = Math.floor(
          (utc.getTime() - new Date(datasentt).getTime()) / 1000 / 60
        );
        this.dateformated = this.date;
        this.datecal = (this.date < 1440);
      });
  }

  cancelOrder() {
    let comment = (this.comment?.length > 0) ? this.comment : '';
    this.globalService.loadingSubject.next(true); 
    this.orderService.deleteWholeOrder(comment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          this.orderService.getOrderDetails();
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  cancelUpdateOrder() {
    this.modalpopup = true;
    this.orderService.cancelupdate(this.modalpopup);
  }

  cancelBtn() {
    this.comment = '';
    this.updatepoup = true;
  }

  recreate() {
    this.globalService.loadingSubject.next(true);
    this.orderService.recreatecart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          this.globalService.getCartsListByLegalEntity();
          this.modalpopup = true;
          this.globalService.recreate(this.modalpopup);
          this.router.navigate([AppConstants.routeUrls.cart]);
        },
        error: (er) => {
          this.globalService.loadingSubject.next(false);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
