<div class="row">
  <div class="col">
    <h2 class="font-weight-bold">{{ "orderDetail.products" | cxTranslate }}</h2>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="row butnpadding">
      <span *ngIf="
          orderDetailsarray?.status == 'Not Yet Processed' &&
          orderDetailsarray?.deliveryMode?.name != 'Turbine down' &&
          datecal && !displayRole
        ">
        <button class="btn cstm-btn-light mb-2" data-toggle="modal" data-target="#exampleModal" data-backdrop="false"
          (click)="cancelBtn()">
          {{ "orderDetail.cancelOrder" | cxTranslate }}
        </button>
      </span>
      <span *ngIf="
          orderDetailsarray?.status == 'Not Yet Processed' &&
          orderDetailsarray?.deliveryMode?.name != 'Turbine down' &&
          dateformated > 1440 && !displayCancelRole && !displayRole
        ">
        <button class="btn cstm-btn-light mb-2" data-toggle="modal" data-target="#exampleModal" data-backdrop="false"
          (click)="cancelUpdateOrder()">
          {{ "orderDetail.cancelOrder" | cxTranslate }}
        </button>
      </span>
      <span class="recreate-btn">
        <button *ngIf="!displayCart" class="btn cstm-btn-primary recreate mb-2" [disabled]="
          orderDetailsarray?.status == 'CANCELLED' ||
          orderDetailsarray?.status == 'REJECTED'
        " (click)="recreate()">
          {{ "orderDetail.RecreateCart" | cxTranslate }}
        </button>
      </span>
    </div>
  </div>
</div>

<div *ngIf="modalpopup">
  <div class="modal fade" tabindex="-1" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            {{ "orderDetail.orderCancel" | cxTranslate }}
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ "orderDetail.orderCreateMessage" | cxTranslate }}</p>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="button" class="btn cstm-btn-primary" data-dismiss="modal">
            {{ "orderDetail.ok" | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="updatepoup">
  <div class="modal fade" tabindex="-1" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            {{ "orderDetail.cancelOrder" | cxTranslate }}
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ "orderDetail.cancelOrderMessage" | cxTranslate }}</p>
          <div>
            <textarea placeholder=" {{'orderDetail.cancelMessage'|cxTranslate}}" rows="3" cols="60"
              [(ngModel)]="comment">
            </textarea>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="button" class="btn cstm-btn-light" data-dismiss="modal" style="float: left">
            <a>{{ "orderDetail.cancel" | cxTranslate }}</a>
          </button>
          <button type="button" class="btn cstm-btn-primary" data-dismiss="modal" style="float: left"
            (click)="cancelOrder()">
            <a> {{ "orderDetail.confirm" | cxTranslate }}</a>
          </button>
          <!-- <button type="button" class="btn cstm-btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
</div>